import React from 'react'

import FaceContainer from '../../components/face-container/FaceContainer'

import './Home.css'

const Home = () => {
        
  return (
    <div>
        <h1 className='home-header'>
            Noise Maker Academy
        </h1>
        <div className='faces-list-container'>
            <FaceContainer />
        </div>
        <div className='footer-container'>
            <h6 className='grease-clan-text'>Product of the Grease Clan</h6>
        </div>
        <div>
          <h6 className='grease-clan-text'>Privacy Policy</h6>
          <div>There is no data collected among using this website or the Noise Maker Academy Mobile App</div>
        </div>
    </div>
  )
}

export default Home