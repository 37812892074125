import React from 'react'

import Face from '../face/Face'

import './FaceContainer.scss'

import image1 from '../../assets/images/griffin_face_1.jpg'
import image2 from '../../assets/images/griffin_face_2.jpg'
import image3 from '../../assets/images/griffin_face_3.jpg'
// import image4 from '../../assets/images/jarrod_face_1.jpg'
// import image5 from '../../assets/images/khristian_face_1.jpg'
import image6 from '../../assets/images/stone_face_1.jpg'
import image7 from '../../assets/images/lurt.jpg'


import sound1 from '../../assets/sounds/griffin_bich.mp3'
import sound2 from '../../assets/sounds/griffin_chicken.mp3'
import sound3 from '../../assets/sounds/griffin_mad.mp3'
import sound4 from '../../assets/sounds/griffin_mary_smoka.mp3'
import sound5 from '../../assets/sounds/griffin_mc_zombie.mp3'
import sound6 from '../../assets/sounds/griffin_mw2_russian.mp3'
import sound7 from '../../assets/sounds/griffin_oh_god.mp3'
import sound8 from '../../assets/sounds/griffin_random_noises.mp3'
import sound9 from '../../assets/sounds/griffin_sound_barrier.mp3'
import sound10 from '../../assets/sounds/griffin_stone_xgames.mp3'
import sound11 from '../../assets/sounds/griffin_tiktok_sound.mp3'
import sound12 from '../../assets/sounds/lurt_burp.mp3'
import sound13 from '../../assets/sounds/stone_behind_us.mp3'
import sound14 from '../../assets/sounds/stone_cricket.mp3'
import sound15 from '../../assets/sounds/stone_drink.mp3'
// import sound16 from '../../assets/sounds/stone_hum_song.mp3'
import sound17 from '../../assets/sounds/stone_leave_the_game.mp3'
import sound18 from '../../assets/sounds/stone_moan.mp3'
import sound19 from '../../assets/sounds/stone_moan_2.mp3'
import sound20 from '../../assets/sounds/stone_omg.mp3'
import sound21 from '../../assets/sounds/stone_roast.mp3'
import sound22 from '../../assets/sounds/stone_wake_up.mp3'
import sound23 from '../../assets/sounds/stone_what_the_freak.mp3'
import sound24 from '../../assets/sounds/stone_zombie.mp3'



const FaceContainer = () => {

    const sound_object = [
        {
            id: 1,
            person: 'Griffin',
            image: image1,
            sound: sound1,
            title: 'Bich'
        },
        {
            id: 2,
            person: 'Griffin',
            image: image2,
            sound: sound2,
            title: 'Chicken'
        },
        {
            id: 3,
            person: 'Griffin',
            image: image3,
            sound: sound3,
            title: 'Mad'
        },
        {
            id: 4,
            person: 'Griffin',
            image: image3,
            sound: sound4,
            title: 'Smoka'
        },
        {
            id: 5,
            person: 'Griffin',
            image: image3,
            sound: sound5,
            title: 'MC Zombie'
        },
        {
            id: 6,
            person: 'Griffin',
            image: image3,
            sound: sound6,
            title: 'MW2 Russian'
        },
        {
            id: 7,
            person: 'Griffin',
            image: image3,
            sound: sound7,
            title: 'Oh God'
        },
        {
            id: 8,
            person: 'Griffin',
            image: image3,
            sound: sound8,
            title: 'Random Noises'
        },
        {
            id: 9,
            person: 'Griffin',
            image: image3,
            sound: sound9,
            title: 'Sound Barrier'
        },
        {
            id: 10,
            person: 'Griffin',
            image: image3,
            sound: sound10,
            title: 'X Games'
        },
        {
            id: 11,
            person: 'Griffin',
            image: image3,
            sound: sound11,
            title: 'TikTok Sound'
        },
        {
            id: 12,
            person: 'Lurt',
            image: image7,
            sound: sound12,
            title: 'Burp'
        },
        {
            id: 13,
            person: 'Stone',
            image: image6,
            sound: sound13,
            title: 'Behind Us'
        },
        {
            id: 14,
            person: 'Stone',
            image: image6,
            sound: sound14,
            title: 'Cricket'
        },
        {
            id: 15,
            person: 'Stone',
            image: image6,
            sound: sound15,
            title: 'Cum Drink'
        },
        
        {
            id: 17,
            person: 'Stone',
            image: image6,
            sound: sound17,
            title: 'Leave The Game'
        },
        {
            id: 18,
            person: 'Stone',
            image: image6,
            sound: sound18,
            title: 'Moan'
        },
        {
            id: 19,
            person: 'Stone',
            image: image6,
            sound: sound19,
            title: 'Moan 2'
        },
        {
            id: 20,
            person: 'Stone',
            image: image6,
            sound: sound20,
            title: 'Oh My God'
        },
        {
            id: 21,
            person: 'Stone',
            image: image6,
            sound: sound21,
            title: 'Roasting Lurt'
        },
        {
            id: 22,
            person: 'Stone',
            image: image6,
            sound: sound22,
            title: 'Wake Up'
        },
        {
            id: 23,
            person: 'Stone',
            image: image6,
            sound: sound23,
            title: 'What The Freak'
        },
        {
            id: 24,
            person: 'Stone',
            image: image6,
            sound: sound24,
            title: 'MC Zombie'
        },
        
    ]

    // removed hum song id 16
    /*
        {
            id: 16,
            person: 'Stone',
            image: image6,
            sound: sound16,
            title: 'Hum Song'
        },
    */

    return (
        <div className='face-container'>
            {sound_object.map((element) => {
                return (
                    <div key={element.id}>
                        <Face image={element.image} sound={element.sound} person={element.person} title={element.title}/>
                    </div>
                )
            })}
        </div>
    )
}

export default FaceContainer