import React from 'react'
import useSound from 'use-sound'

import './Face.scss'

const Face = ({image, sound, person, title}) => {

    const [playActive] = useSound(
        sound,
    )
    // how to stop audio
    // play sound, have if active === true, stop sound, set active to true, after finished playing, set active back to false

    return (
        <div className='face-element'>
            <div className='face-container-elements'>
                <img src={image} alt='ugly face' className='face-image' onClick={playActive}/>
                <div className='text-container'>
                    <div className="sound-title-container">
                        <h2 className='sound-title-text'>{title}</h2>
                    </div>
                    <h3 className='person-text'>{person}</h3>
                </div>
            </div>
        </div>
    )
    }

export default Face